.checkboxContainer {
  align-items: center;
  display: flex;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  justify-content: center;
  margin-left: -8px;
  -webkit-transition: all ease-in 0.1s;
  transition: all ease-in 0.1s;
  &.checked {
    background-color: rgba(0,0,0,0.05);
  }
  img {
    width: 20px;
    height: 20px;
  }
  &.circle {
    width: 24px;
    height: 24px;
    img {
      width: 16px;
    }
  }
}