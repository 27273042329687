.expandableSearch {
    transition: width 0.2s cubic-bezier(0.4, 0, 1, 1);
    background: #fff;
    box-shadow: var(--tileBoxShadow);
    border: 0px solid rgb(189, 189, 189);
    border: 1px solid transparent !important;
    // border: 1px solid var(--borderColor) !important;
    outline: none;
    border: none;
    padding: 8px 12px;

    &.hidePlaceholderImage {
        &::placeholder {
            background-image: none;
        }
    }

    &::placeholder {
        background-image: url('../../assets//images/search.svg');
        background-repeat: no-repeat;
        background-size: 14px;
        text-indent: 20px;
        background-position-y: calc(50%);
        font-size: 14px;
    }

    &:focus {
        // box-shadow: 0px 0px 3px 1px var(--theme-light);
        // outline: none;
        border: 1px solid var(--theme-light) !important;
        background-color: #fff;
        // border-color: var(--backgroundColorOnHover);

        &::placeholder {
            width: 100%;
            background-image: none;
            background-size: initial;
            text-indent: initial;
        }
    }
}

.onEnterImgInput {

    .closeTextImage,
    img {
        position: absolute;
        right: 28px;
        top: 9px;
    }

    .closeTextImage .appBlue {
        font-size: 12px;
        line-height: 1;
        margin-left: 1.5px;
        font-weight: 300;
    }

    input {
        &:focus {
            &::placeholder {
                color: transparent;
            }
        }
    }

}