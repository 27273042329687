@import "src/assets/scss/variables";

.popupHeader {
  position: relative;
  font-size: 16px;
  color: #000;

  h3 {
    font-weight: 500;
  }

  &.dark {
    border: solid 0.5px #000000;
    background-color: #232a44;

    * {
      color: #ffffff !important;
    }
  }
}

.popup-container {
  margin-bottom: 0px;
  padding: 20px;
  border-radius: 4px;

  .popupBody {
    position: relative;
    padding: 8px 0px 0px;

    .row {

      .col-6:nth-child(odd),
      .col-12:nth-child(odd) {
        padding-left: 1px;
        padding-right: 0px;
      }

      .col-12:nth-child(even),
      .col-6:nth-child(even) {
        padding-left: 16px;
        padding-right: 0px;
      }
    }

    label,
    .label {
      margin: 16px 0px 8px;
    }

    .workflowNameCont {
      padding: 16px 0px 0px;

      &.disabled {
        opacity: 0.6;
      }

      div:nth-child(2) {
        margin-left: 12px;

        .prospectName {
          margin: 0px 0px 6px;
          padding-left: 0px;
        }

        .prospectDesg {
          margin: 0px;
        }
      }
    }

    input,
    select,
    textarea {
      max-width: 100%;
      // margin-bottom: 8px;
    }

    .helperText {
      width: 220px;
      margin: auto;
      margin-top: 20px;
    }

    span:first-child {
      padding-left: 4px;
    }

    span {
      margin-top: 0;
      color: #565757;
      display: block;
    }
  }

  .popupFooter {
    width: 100%;
    margin-top: 16px;
  }

  .popupBody .greyLabel {
    margin: 4px;
    width: max-content;
  }

  .popupBody p {
    margin-top: 0px;
  }

  span>p {
    display: inline;
    margin-left: 8px;
  }

  .popupHeader .fa {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    margin-left: 8px;
    right: 0px;
  }

  .popupHeader .fa-info-circle {
    right: auto;
  }

  .popupBody input[type="text"],
  .popupBody textarea {
    width: 100% !important;
  }

  .popupBody textarea {
    height: 50px;
  }

  .buttonSec {
    display: flex;
    padding: 24px 0px 0px;
    justify-content: flex-end;
  }

  .buttonSec.prospectError {
    justify-content: space-between;
  }

  .pickList {
    margin: 20px 0;
    border-radius: 8px;
    padding: 0;
    border: solid 0.5px var(--borderColor);
  }

  .pickListItems li {
    position: relative;
    padding: 4px 4px 4px 24px;
    border-bottom: solid 0.5px var(--borderColor);
  }

  .pickListItems li:last-child {
    border: none;
  }

  .pickListItems li .fa-arrows {
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
  }

  .pickListItems li .fa-trash,
  .pickListItems li .addButton {
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
    padding: 6px 8px;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
  }

  .pickListItems li .addButton {
    box-shadow: none;
    border-radius: 0%;
    top: 4px;
    right: 8px;
    font-weight: bold;
  }

  .nameTags {
    padding: 0;
    margin: 0 -4px;
  }

  .nameTags span {
    display: inline-block !important;
    margin: 8px;
  }

  .fa-linkedin-square {
    color: var(--theme-light);
  }

  h4 {
    text-transform: capitalize;
    font-size: 16px;
  }

  .row {
    position: relative;
  }

  .prospects-empty {
    height: 100px;
    text-align: center;

    font-size: 14px;
    line-height: 1.5;
    color: #565757;
    padding-top: 40px;
  }
}

.logCalls {
  .popupBody {
    overflow-y: scroll;
    margin: 0px 0px 20px;

    span {
      margin: 0;
      display: initial;
    }
  }

  .cta {
    padding: 8px 20px;
  }

  .button-save {
    width: -webkit-fill-available;
  }

  .iconSetCont img,
  .iconSetSearch img,
  .iconSetSearch i {
    width: 28px;
    height: 28px;
  }
}

.popupBody {
  .phone {
    display: inline-flex !important;
    width: 100%;
    align-items: center;
    border-radius: 4px;
    font-weight: 300;
    color: #565757;
    border: none;
    padding: 6px 12px;
    background-color: rgba(196, 196, 196, 0.1);

    input[type="radio"] {
      width: 13px !important;
      margin-bottom: 0px;
      margin-top: 0px;
      margin-right: 8px !important;
    }
  }
}

.sendclaimlink-modal {
  .copy-link-button {
    margin-left: 16px;
    background: transparent;
    border: transparent;
    color: var(--theme-light);
    font-size: 16px;
    margin-top: -4px;
    border-left: 1px solid darkgrey;
  }

  .invitation-link-users {
    width: 500px !important;
    border-right: 1px solid darkgrey;
    color: #6f6f70;
  }

  .popupBody input,
  .popupBody textarea {
    width: 500px !important;
    padding: 8px;
    font-size: 14px;
    background: transparent;
    background-color: transparent;
  }
}

.add-time-block {
  max-width: 550px;
}

.add-brandedurl {
  .set {
    text-align: center;
  }

  .blueLabel {
    padding: 3px 290px;
    background-color: #c7f1ff61;
  }
}

.add-phone-number {
  .set {
    text-align: center;

    .img2 {
      margin-left: 40%;
      margin-right: 50%;
      width: 20%;
      height: 20%;
    }

    .img1 {
      margin-left: 35%;
      margin-right: 50%;
      width: 32%;
      height: 32%;
    }
  }
}

.changeCredCont {
  border-radius: 8px;

  .helperCont {
    margin-top: 16px !important;
    border-top: 1px solid #c4c4c4;
    padding: 16px 8px 0px;
  }
}

.custom-modal-container.editWorkflowModel {
  max-width: 450px;

  .radioIcon {
    width: 16px;
    height: 16px;
  }
}

.custom-modal-container.shareWorkflowModel {
  max-width: 550px;

  .sharedUsers {
    margin: 20px 0px;
    max-height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;

    .removeUser {
      margin-top: 6px;
    }

    .userTitle {
      font-size: 14px;
      margin-top: 0px;
    }
  }
}

.demoCompleteModal {
  .popupBody {
    position: relative;
    padding: 10px 4px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .demo-content {
    justify-content: center;
    align-items: center;
    display: flex !important;
    flex-direction: column;
  }

  .hurray-image {
    width: 300px;
    margin-left: 48px;
  }

  .demo-content-text {
    font-size: 20px;
    line-height: 1.5;
    color: #565757;
  }

  .hurray-text {
    font-size: 30px;
    text-align: center;
  }

  .ellipseDemo {
    position: absolute;
    width: 150px;
    bottom: -12px;
    right: -16px;
  }

  .demoLeftBtn,
  .demoRightBtn {
    bottom: 0px;
    position: absolute;
    border: none;
    background: transparent;

    font-size: 16px;
    font-weight: bold;
    color: var(--theme-light);
  }

  .demoRightBtn {
    right: 0px;
  }

  .demoLeftBtn {
    left: 0px;
  }

  .welcome-image {
    width: 550px;
    height: 300px;
    margin: 0px;
  }

  .text-welcome {
    width: max-content;
    margin-bottom: 6px;
  }

  .watch-later-button {
    right: 800px;
  }
}

.assignWorkflow {
  width: 500px;
  max-width: 95%;

  .popupBody {
    padding-top: 0px;
  }

  .popupHeader {
    margin-bottom: 0px;
  }

  .workflow-selected {
    border-top: 2px solid var(--borderColor);
    border-bottom: 2px solid var(--borderColor);
    padding: 15px;

    color: #565757;

    .title {
      font-size: 16px;
      font-weight: 500;
      padding-bottom: 2px;
    }

    .desc {
      font-size: 12px;
    }
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.task-text-header {
  font-weight: 300;
  margin-bottom: 8px;
}

.add-meeting-modal {
  height: 84vh;
  width: 70vw;
  max-width: 90vw;

  .meeting-icons {
    height: 18px;
    width: 18px;
    margin: 18px 8px 0 0;
  }

  .popupBody {
    input[type="text"] {
      width: 90% !important;
    }

    textarea {
      width: 90% !important;
    }
  }

  .div-margin {
    margin-left: 28px;
  }
}

.ViewMeetingDetails-modal {
  width: 700px;

  h4 {
    font-size: 16px !important;
    color: rgba(86, 87, 87, 0.7);
  }

  .email-attendee {
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    color: var(--theme-light);
  }

  .prospect-list {
    position: relative;
    left: -16px;
    top: -6px;
  }

  .icons-prospect {
    width: 24px;
    height: 24px;
  }

  .orange-dot {
    background: var(--orange);
    margin-top: 8px;
  }

  .icon-background {
    border: solid 2px #00aaee;
    margin: 4px 0 0 0;
  }

  .name-initials {
    font-size: 14px;
    margin-top: 2px !important;
    color: #ffffff !important;
    margin-left: -4px;
  }

  .name-list {
    display: block;
    position: absolute;
    left: 89px;
    top: 6px;
  }

  .designation {
    margin: -4px 0 0 0;
  }
}

.line-hor {
  width: 100%;
  height: 2px;
  background-color: rgba(0, 0, 0, 0.25);
}

.terms-of-service {
  font-size: 14px;
  color: #565757;
  padding: 0;
  // max-height: 70vh;

  h4 {
    font-size: 30px !important;
    text-transform: none !important;
    font-weight: 500;
  }

  h6,
  p,
  h5 {
    font-size: 13px;
    line-height: 150%;
  }

  i {
    height: 30px;
    width: auto;
  }

  .terms-left-panel {
    padding-left: 24px;
    padding-top: 96px;
    font-size: 16px;
    background-color: #f8f8f8;
    border-radius: 8px 0px 0px 8px;

    .left-panel-button {
      padding: 10px 0;
      opacity: 0.6;
      cursor: pointer;

      .index-number {
        background-color: var(--theme-light);
        height: 35px;
        width: 35px;
        color: #fff;
        border-radius: 50%;
        margin-right: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .index-name {
        color: var(--theme-light);
        font-weight: bold;
      }
    }

    .left-panel-button:hover {
      opacity: 1;
    }
  }

  .terms-right-panel {
    padding: 24px;

    .terms-listed {
      padding: 0 20px 20px 0px;
      height: 60vh;
      overflow-y: scroll;

      .content {

        ol,
        li {
          font-size: 13px;
          line-height: 150%;
        }
      }
    }
  }

  .term {
    padding: 14px 0px 8px 0px;

    .head {
      position: relative;
      height: 34px;

      .head-caption {
        position: absolute;
        background-color: #fff;
        padding-right: 10px;
        font-size: 20px;
        font-weight: bold;
      }
    }

    h5 {
      font-weight: bold;
    }
  }
}

.ellipsis-dropdown-container {
  position: absolute;
  right: 42px;
  width: 200px;
  z-index: 19;
  top: 0px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.35);

  .row {
    padding: 12px;
    cursor: pointer;
    border-bottom: 0.5px solid var(--borderColor);

    &:last-child {
      border-bottom: none;
    }
  }

  .row:hover {
    background-color: var(--backgroundColorOnHover);
  }
}

.ellipsis {
  cursor: pointer;
  display: flex;
  width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
}

.ellipsisClicked {
  border-radius: 50%;
  background-color: rgba(196, 196, 196, 0.2);
}

.notification-popup {
  width: 600px;
  background-color: #fcfbfb;

  .notification-card {
    width: 100%;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.05);
    background-color: #ffffff;
    border-left: 4px solid #2196f3;
    padding: 12px 12px 12px 0;
    margin-bottom: 6px;
  }

  .notification-icons {
    // margin-left: 12px;
    width: 32px;
    height: 32px;
  }

  .notification-text {
    font-size: 14px;
  }

  .notification-time {
    font-size: 12px;
  }

  .notification-body {
    padding: 20px;
  }

  .notification-header-text {
    font-size: 16px;
    font-weight: 500;
    color: #565757;
    margin-bottom: 6px;
  }

  .notification-button {
    background: #ffffff;
    justify-content: center;
    text-align: center;
    padding: 12px;
    font-size: 16px;
    font-weight: bold;
    color: var(--theme-light);
  }
}

.add-filter {
  max-height: 250px;
  overflow: scroll;

  .filter-items {
    padding: 12px;
  }

  .filter-section {
    margin-left: 8px;
  }
}

.dismiss-button {
  height: 36px;
  width: 36px;
  font-size: 16px;
  background: transparent;
  border: none;
  font-weight: 600;
  color: #0c2e57;
}

.edit-smart-view {
  .filters-list {
    margin-top: 8px;
    overflow-x: auto;
    flex-wrap: wrap;
  }

  .label {
    margin: 12px 0px 8px;
  }
}

#viewEmail-modal {
  .settings-container>.popupHeader {
    // background-color: #fafcfe;
    padding: 24px;
    position: relative;
    font-size: 16px;
    padding: 12px 16px;
    margin-bottom: 4px;
    color: #fff;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: var(--theme);
    font-weight: 500;

    h3 {
      font-weight: 500;
      padding-left: 51px;
      color: #fff !important;
    }
  }

  #viewEmailBody {
    .ellipsis-dropdown-container {
      top: 26px;
      right: 9px;
    }

  }
}

.viewEmail-modal,
.viewEmail-mailbox-modal {
  width: 79vw;

  .close-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 32px;
  }

  .email-buttons {
    font-size: 14px;
    align-items: center;
    border: none;
    display: inline-flex;
    justify-content: center;
    outline: none;
    position: relative;
    letter-spacing: .25px;
    background: none;
    border-radius: 4px;
    color: #5f6368;
    cursor: pointer;
    font-weight: 500;
    height: 36px;
    padding: 0 16px;
    box-shadow: inset 0 0 0 1px #dadce0;
    min-width: 104px;
    padding-left: 12px;
    user-select: none;
    margin-right: 12px;
    text-decoration: none;

    &:hover {
      background-color: rgba(32, 33, 36, 0.059);
    }
  }

  .popup-footer {
    padding: 0px 24px 24px;
    padding-left: 66px;
  }

  .popupBody {
    // padding: 0px 16px 16px;
    padding: 0px 16px;
    margin: 12px 0px;

    .time-block {
      font-size: 12px;
      font-weight: 300;
      color: #565757;
      white-space: nowrap;
    }

    .email-id {
      font-size: 14px;
      color: #565757;
      margin-left: 6px;
    }

    .email-body {
      word-break: break-word;
      border-bottom: 1px solid var(--borderColor);
    }

    .emailTooltiptext {
      background-color: #fff;
      padding: 12px 16px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      z-index: 100;
      position: absolute;
      // max-width: 90%;
      max-height: 100px;
      overflow: hidden;
      left: 15px;
      top: 42px;
      overflow-y: auto;

      tr {

        vertical-align: baseline;

        td:first-child {
          font-weight: 300;
          text-transform: capitalize;
        }

        td:last-child {
          padding-left: 6px;
          padding-bottom: 4px;
        }
      }

      &:hover {
        overflow: auto;
      }
    }

    .attachmentContainer {
      padding: 8px 14px 0px 50px;

      .attachment-class {
        margin-bottom: 0px;
      }
    }
  }

  .reply-editor {
    // padding: 15px 30px;
    padding: 4px 14px 0px;
    margin-left: 34px;
    border-radius: 8px;
    border: 1px solid var(--borderColor);
    width: 100%;

    .sect {
      border-bottom: 1px solid var(--borderColor);

      .label {
        // margin-right: 20px;
        width: 30px;
      }

      .react-select-container {
        .react-select__control {
          background-color: #fff !important;

          .react-select__single-value {
            font-weight: 500;
          }
        }
      }

      input[type="text"] {
        background: none;
        border-radius: none;
        font-weight: 500;
      }

      .field-dropdown-close {
        top: 40px;
      }

      .to-additional {
        cursor: pointer;
        color: inherit;
        background: none;
        border: none;
        margin-left: 12px;
        padding: 0px;
      }

      .to-additional.disabled {
        cursor: not-allowed;
      }
    }

    .textEditorSect {
      .preview-box {
        border: 0px solid var(--theme-light);
        border-radius: 8px;
        padding: 10px 0px;
        min-height: calc(47vh - 3px);
        max-height: calc(47vh - 4px);
        overflow-x: hidden;
        overflow: auto;
        overflow-wrap: anywhere;
      }

      .previewButton {
        margin-bottom: 8px;

        .filledButton {
          font-weight: 500;
          font-size: 14px;
        }
      }
    }
  }
}

.addSalesforceDataMappingCont {
  padding: 0px;
  border-radius: 8px;

  .popupHeader {
    padding: 18px 24px;
    background-color: #565757;
    color: #ffffff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    i,
    .fa {
      right: 24px;
    }
  }

  .popupBody {
    padding: 0px 24px 24px 24px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

.lock-user-modal {
  width: 500px;
}

.mailboxSearchEmailCont {
  padding: 16px;
  padding-bottom: 0px;

  .attachment {
    margin-top: 10px;
  }

  .buttonSec {
    padding: 16px;
  }

  .row {
    .col-md-4:last-child {
      padding-right: 0px;
    }
  }
}

.logCallMaxheight {
  max-height: calc((100vh - 30px) - 44px - 100px - 10px);
  overflow-y: auto;
}

.phoneCallPopup {
  border-radius: unset;
  padding: 0px;
  height: 100%;

  .overFlowCont.popupBody {
    height: calc(100% - 100px);
  }

  &.minimize {
    padding: 6px 16px;
    background: var(--theme);
    border-radius: 4px;
    color: white;

    .popupHeader {
      padding: 0px;
    }
  }

  .popupHeader {
    padding: 12px 18px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .popupBody {
    padding: 0px 18px 0px 18px;

    .logText {
      font-size: 15px;
      // color: #9F9F9F;
      font-weight: 700;
      padding-bottom: 8px;
    }

    .phoneNumber {
      // font-size: 16px;
      // color: black;
      font-weight: 500;
      // text-align: center;
      padding-bottom: 8px;
    }
  }

  .actionControl {
    width: calc(100% + 36px);
    margin-left: -18px;

    .iconSetSearch {
      width: 100%;
      justify-content: space-around;
      border-top: 1px solid var(--borderColor);
      margin-left: 0px;
      padding: 4px 0px;

      li {
        padding: 4px;
      }

      li:hover {
        background-color: var(--backgroundColorOnHover);
        border-radius: 50%;
      }
    }
  }

  .expandBtn {

    .material-symbols-outlined,
    .fa {
      margin-top: 2px !important;
      align-items: center;
      display: flex;
      justify-content: center;
      position: absolute;
      width: 24px;
      height: 24px;
      cursor: pointer;
      border: 1px solid var(--borderColor);
      border-radius: 50%;
      color: var(--theme-light) !important;
      font-weight: 700;
      background-color: white;
    }
  }

  .cta {
    padding: 8px 20px;
  }

  .button-save {
    width: -webkit-fill-available;
    padding: 12px 18px;
  }

  .smallTxt {
    padding-left: 8px;
    margin-bottom: 0px;
  }

  .voicemail {
    position: absolute;
    z-index: 2;
    top: 58px;
    right: 20px;

    .voicemailPopupCont {
      padding: 16px;

      h3 {
        color: #000000;
        font-weight: 500;
      }
    }

    .popupBody {
      text-align: left;
      padding-left: 0px;
    }
  }

  .buttonSec {}
}

.newTaskModal {
  width: 550px;
  margin-top: 0px;

  #newTaskModal {
    max-height: 95vh;
    overflow-y: auto;
  }

  .custom-modal-title {
    margin: 0px;
  }

  h4 {
    text-transform: capitalize;
  }

  .error {
    padding: 0px 8px;
    margin-top: 8px !important;
  }
}

.addAccountModal {
  width: 500px;
  max-height: 100vh;

  .popupBody {
    max-height: calc(90vh - 80px);
    overflow: hidden;
    overflow-y: auto;
    padding-right: 6px;

    .row {

      .col-6:nth-child(odd),
      .col-12:nth-child(odd) {
        padding-left: 1px;
        padding-right: 0px;
      }

      .col-12:nth-child(even),
      .col-6:nth-child(even) {
        padding-left: 16px;
        padding-right: 0px;
      }
    }

    .headline {
      margin-top: 20px;
      margin-bottom: 0px;

      .material-symbols-outlined {
        padding-left: 0px;
      }
    }
  }
}

.phoneCallModal,
.incomingCallPopup-modal {
  width: 500px;

  .popup-container .buttonSec button {
    margin-left: 0px;
  }
}

#save-customised-workflow-modal {
  .label {
    font-size: 16px;
  }
}

#addCustomFieldModal,
#add-new-email-alias {
  min-width: 400px;
  max-width: 400px;
}

.signature-popup-input {
  margin-top: 24px;
  margin-bottom: 24px;
}

#add-time-block {
  max-width: 500px;
  min-width: 500px;

  .popupBody {
    .ctaUnderscore:hover {
      font-weight: bold;
    }
  }
}

#add-new-email-alias {
  .popupBody {
    span {
      margin-top: 0px;
    }
  }
}

#linkedIn-task-message {
  max-width: 550px;
  min-width: 550px;
}

#save-customised-template-modal {
  max-width: 500px;
  min-width: 500px;

  .templateSharingOption {
    font-size: 12px;
    color: #555655;
    margin: 5px 0px 10px;
  }
}

.edit-smart-view .show-filters,
.selectedColumnDraggableList .show-filters {
  border-radius: 4px;
  background-color: rgba(25, 118, 210, 0.1);
  padding: 6px 4px 6px 8px;
  font-size: 14px;
  margin-right: 8px;
  margin-bottom: 12px;
  position: relative;

  &:last-child {
    margin-right: 0px;
  }

  button {
    margin-left: 6px;
    width: 16px;
    height: 16px;

    img {
      height: 16px;
      padding-right: 4px;
    }
  }
}

.selectedColumnDraggableList .show-filters {
  margin-bottom: 8px;
}


#customiseTableColumn-modal {
  max-width: 54vw;
  min-width: 54vw;
  max-height: 53vh;
  min-height: 53vh;

  .popupBody {
    .label {
      margin-bottom: 0px;
    }

    .expandableSearch {
      margin-left: 0px;
      padding: 4px;
      margin-bottom: 8px;
      width: 100%;
      display: flex;

      input {
        background-color: #fff;
      }

      img {
        width: 14px;
      }
    }

    .allColumnList,
    .selectedColumnDraggableList {
      max-height: calc(53vh - 168px);
      min-height: calc(53vh - 168px);
      overflow-x: hidden;
      overflow-y: auto;
    }

    .allColumnList {
      .label {
        margin: 0px 0px 8px 3px;
      }
    }

    .selectedColumnDraggableList {
      padding: 0px 12px;

      .show-filters {
        margin: 0px 0px 6px;
        width: 100%;

        .dragToRearrange {
          display: flex;
          align-items: center;

          .fontsm {
            visibility: hidden;
            margin-right: 16px;
          }
        }

        &:hover {
          .fontsm {
            visibility: visible;
          }
        }

        .content {
          display: flex;
          align-items: center;

          span:first-child {
            padding-left: 0px;
            margin-right: 2px;
          }
        }
      }

      .borderUl li {
        border-top: unset;
      }

      .mandatoryColumns {
        background-color: rgba(162, 205, 255, 1);
      }
    }

    .action-btn {
      padding-top: 10px;
    }
  }
}

.addField {

  #bigSelectField {
    .react-select {

      &-container,
      &__control,
      &__value-container {
        height: 48px !important;
        overflow: hidden;
        padding-right: 6px;
      }
    }
  }

}