button,
button[type="button"],
button[type="button"]:focus,
span[role="button"],
span[role="button"]:focus,
p[role="button"],
p[role="button"]:focus,
div[role="button"],
div[role="button"]:focus {
  outline: none;
  cursor: pointer;
}

/* Scrollbar */
/* Just add overFlowCont class to any div which needs a scrollbar */
::-webkit-scrollbar {
  height: 3px;
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--borderColor);
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

.react-select {
  margin-bottom: 8px;
  // background-color: #fdfdfd;

  &__control {
    // border: none !important;
    // background-color: rgba(196, 196, 196, 0.1) !important;
    overflow-y: scroll;

    input {
      height: auto !important;
    }
  }

  &__value-container {
    padding-top: 0;
    padding-bottom: 0;
  }

  &__group {
    &-heading {
      padding-top: 8px;
      padding-bottom: 8px;
      background-color: transparent;
      color: var(--theme-light) !important;
      font-size: 14px !important;
      text-transform: capitalize !important;
    }

  }

}

.sk-spinner.line-scale {
  div {
    height: 21.875px;
    width: 2.5px;
    // margin: 1.25px;
  }
}

.react-select-container {

  .react-select__control {
    // background-color: #fdfdfd;
    line-height: 1.14;
    box-shadow: none;
    // min-height: 24px !important;
    max-height: 60px;
    min-height: 32px;

    border-color: var(--borderColor);

    .react-select__single-value {
      color: #565857;
    }

    .react-select__multi-value {
      background-color: transparent;
      border: solid 0.5px var(--borderColor);
      border-radius: 12px;
      font-weight: 500;
      display: flex;
      align-items: center;

      &__label {
        font-size: 12px;
        line-height: 1;
        padding: 3px 8px 3px 8px;
      }

      &__remove {
        margin-left: -4px;
        padding-left: 0px;

        &:hover {
          background-color: transparent;
          // padding: 0px;
          // border-radius: 50%;

          svg {
            color: var(--theme-light);
          }
        }
      }
    }

    .react-select__value-container {
      padding: 0px 8px;
    }

    svg {
      height: 18px;
      width: 14px;
    }

    .react-select__indicators {
      display: flex;
    }
  }

  .react-select__indicator {
    padding: 2px;
  }
}

.__react_component_tooltip {
  background-color: black !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  // padding: 4px 20px !important;
}

.groupOptionsLabelCont {
  font-size: 14px !important;
}

.link {
  text-decoration: none;
  color: inherit;
  cursor: pointer;

  &:hover {
    color: var(--primary-color);
  }
}

button:disabled {
  background-color: rgb(226, 222, 222);
  cursor: not-allowed;
}

.ctaOpt:disabled,
.cta:disabled {
  cursor: not-allowed;
}

.ctaOpt {
  &:disabled {
    border: none;
    color: #fff;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

// DONOT remove this
.redDot {
  background: #d00334;
  box-shadow: 0 0 0 rgba(174, 115, 39, 0.4);
  animation: pulse 2s infinite;
}

.absoluteCenter {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.absoluteCenterYAxis {
  position: absolute !important;
  top: 50%;
  transform: translateY(-50%);
}

.itemsCenter {
  align-items: center;
  display: flex;
}

// Donot remove this
.userCont {
  display: flex;
  flex-direction: column;
}

.username-users {
  border-radius: 4px;
  font-size: 14px;
  // font-weight: 500;
}

.profileImgPlaceholder {
  width: 35px;
  height: 35px;
  background-color: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: bold;

  span {
    margin: auto;
  }
}

.workflow-icons {
  padding-top: 4px;
  font-size: 16px;
}

.iconSetSearch {
  display: flex;
  margin-left: 8px;
}

.caption {
  align-self: center;
  font-size: 24px;
}

.smallIcon {
  width: 24px !important;
  height: 24px !important;
  margin: 6px !important;
}

.iconGreen {
  color: #03d03c;
}

.colored-star {
  color: goldenrod;
  font-size: 18px;
}

.note {
  font-style: italic;
  font-size: 12px;
  margin: 8px 0px;
}

.loading {
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100%;
}

.PhoneInput {
  height: 32px;
  padding-left: 12px;
  border-radius: 4px;

  input {
    border: none;
    margin: 0;
    outline: none;
    background-color: transparent !important;
  }
}

.showPhoneIconLiReactTooltip {
  .__react_component_tooltip {
    display: inline-block;
  }
}

.textEditorSect {
  position: relative;

  .fr-box.fr-basic.fr-bottom .fr-wrapper,
  .fr-toolbar {
    border: none;
  }

  .fr-toolbar {
    margin-left: -4px;
    margin-bottom: 8px;
    // border-top: 1px solid #ccc;
  }

  .fr-box .fr-wrapper .fr-view {
    padding: 10px 0px;
  }

  .richEditorButtons {
    position: absolute;
    bottom: 8px;
    right: 0px;
    z-index: 20;

    button {
      margin: 0 0 0 10px;
    }
  }
}

.attachment-class {
  z-index: 2;
  margin: 12px 0px;
  display: flex;
  align-items: center;

  .attachmentMessage {
    width: 21px;

    img {
      width: 18px;
      height: 18px;
      transform: rotate(90deg);
    }
  }

  .attachmentCont {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    overflow-y: scroll;
    max-height: 52px;
    width: calc(100% - 21px);

    .attachment {
      padding: 2px 6px;
      align-items: center;
      display: flex;
      // background: aliceblue;
      background: #f5f5f5;
      border-radius: 2px;
      margin: 0px 0px 6px 6px;
      font-size: 12px;
      position: relative;
      cursor: pointer;
      max-width: calc(33% - 6px);

      &:hover {
        // color: rgba(25, 118, 210, 1);
        background-color: rgba(32, 33, 36, 0.122);
        font-weight: 500;
      }

      div:first-of-type {
        outline: none;
      }

      .closeBtn {
        margin-left: 6px;
        align-items: center;
        display: flex;
        height: 14px;

        >img {
          width: 14px;
          height: 14px;
        }
      }
    }
  }
}

.WYSISWYG-spinner {
  position: relative;

  .overlay {
    top: 0;
    left: 0;

    .sk-three-bounce {
      background-color: transparent;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.triggerHeader {
  background-color: #fff;
  padding: 16px 16px 12px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--borderColor);
  color: var(--textColorBlack);
}

.setting-trigger-general {
  .trigger {
    position: relative;
    min-height: 53px;
    background-color: #fff;
    padding: 8px 16px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    &:hover {
      background: var(--backgroundColorOnHover);
      color: var(--theme-light);
      // box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0,
      // 0 1px 2px 0 rgba(0, 0, 0, 0.3), 0 1px 2px 1px rgba(0, 0, 0, 0.15);
    }

    .content {
      display: flex;
      align-items: center;

      .disposition-title {
        margin-left: 12px;
        width: 40vw;
      }

      .highlightedIcon {
        background: rgba(239, 235, 235, 0.25);
        padding: 8px;
        border-radius: 50%;
        overflow: hidden;
        cursor: pointer;
        transition: 0.4s;
        font-weight: 500;
      }

      .highlightedIcon:hover {
        background: rgba(0, 0, 0, 0.05);
        color: var(--textColorBlack);
      }

      .days {
        font-size: 14px;
        font-weight: 300;
      }

      .count {
        font-size: 18px;
        display: inline-block;
        text-align: center;
      }

      .disposition-status {
        margin-left: 200px;
      }

      .countText {
        font-size: 14px;
        font-weight: 300;
        display: block;
      }

      .contentIcons {
        display: none;
        margin-left: 24px;

        i {
          display: inline;
          cursor: pointer;
        }

        i:first-child {
          margin-right: 16px;
        }
      }
    }

    label {
      margin: 0px 24px 0px 16px;
    }
  }

  .trigger:hover .contentIcons {
    display: inline-flex;
  }

}

.setting-triggerList {
  max-height: calc(100vh - 168px) !important;
  min-height: calc(100vh - 168px) !important;

  .settingsCont.noTriggers,
  .blank-tasks {
    max-height: calc(100vh - 16px);
    min-height: calc(100vh - 168px);
  }

  .switch,
  .slider {
    margin-left: 0px !important;
  }

  .draggableList {
    .trigger {
      min-height: 56px;
      height: unset;
      padding: 8px 16px;

      .content {
        .triggerName {
          margin-left: 0px;
          width: 80%;

          div {
            margin-left: 0px;
          }

          .contentText {
            font-size: 15px;
            font-weight: 500;
          }

          .actionDescription {
            font-size: 12px;
            font-weight: 300;
            font-style: italic;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            display: -webkit-box;
          }
        }
      }
    }
  }
}

/* donot remove this */
.iconSetCont {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  display: none;
  // z-index: 1;
}

.action-btn {
  display: flex;
  justify-content: flex-end;
}

.blank-tasks {
  font-size: 12px;
  line-height: 2rem;

  img {
    width: auto;
    min-height: 200px;
  }
}

.dialpadIcon {
  margin: 2px 0px 2px 4px;
  padding-right: 0px;
  font-size: 20px;
}

.preview-email-body {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 13px;
  color: #222;
  line-height: 1.6;
}

.flex1 {
  flex: 1;
}

.borderUl {
  li {
    border-top: 1px solid var(--borderColor);

    &:nth-child(1) {
      border-top: none;
    }
  }
}

.lineHeight {
  &-14 {
    line-height: 1.4;
  }
}

.Blink {
  animation: blinker 0.9s cubic-bezier(.5, 0, 1, 1) infinite alternate;
}

@keyframes blinker {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}