.App-container {
  background-color: var(--backgroundColor);
  height: 100vh;

  .globalAppSelection {
    position: absolute;
    right: 16px;
    top: 16px;
    z-index: 9999;
  }

  .iconSetSearch {
    display: flex;

    li {
      display: inline-flex;
      margin: 0px 4px;
      overflow: hidden;
      cursor: pointer;
      font-weight: 500;
    }

    li:nth-child(1) {
      margin-left: 0px;
    }
  }

  .customTab {
    display: inline;
  }

  .customLabel {
    margin: 20px 0;
    margin-left: -8px;
  }

  .personaLabel {
    margin-top: 12px;
  }

  .checklistSettings {
    margin-top: 12px;

    .switch {
      margin-bottom: 0px;
    }
  }

  .reports-container {

    .material-icons {
      &:hover {
        background: none;
      }
    }

    .blank-tasks {
      height: calc(100vh - 330px);
    }
  }

  .freeTrialIndication {
    padding: 6px;
    top: 15px;
    color: unset !important;
    font-size: 14px;
    left: 50%;
    transform: translate(-50%, -50%);

    .highlight {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.react-select-container {
  &.green {
    .react-select__control {
      border-color: var(--green);
    }
  }

  &.red {
    .react-select__control {
      border-color: #FF6661;
    }
  }

  &.blue {
    .react-select__control {
      border-color: var(--theme-light);
    }
  }

  &.warning {
    .react-select__control {
      border-color: var(--warningDark);
    }
  }
}