$color_1: var(--textColorBlack);
$color_2: #fff;
$color_3: var(--darkcolor);
$background-color_1: #fff;

/* Donot remove this */
/* .appTable thead th .material-icons {
  margin-right: 8px;
} */

.appTable {
  width: 100%;
  border-collapse: collapse;
  padding: 15px;
  text-align: left;
  background-color: $background-color_1;
  overflow-y: auto;

  thead {
    position: sticky;
    top: 0 !important;
    z-index: 1;
    background-color: white;

    tr {
      th {
        i {
          padding-left: 5px;
          cursor: pointer;
        }
      }
    }

    th {
      position: sticky;
      top: 0;
      z-index: 1;
      background: white;

      .hoverIcon {
        visibility: hidden;
        display: inline-flex;
      }

      &:hover {
        .hoverIcon {
          visibility: visible;
        }
      }
    }
  }

  tfoot {
    position: sticky;
    bottom: 0 !important;
    // z-index: 1;

    tr {
      td {
        i {
          padding-left: 5px;
          cursor: pointer;
        }
      }
    }

    td {
      position: sticky;
      top: 0;
      z-index: 1;
      background: white;

      .hoverIcon {
        visibility: hidden;
        display: inline-flex;
      }

      &:hover {
        .hoverIcon {
          visibility: visible;
        }
      }
    }
  }

  tr {
    td {
      border-bottom: 0.5px solid var(--borderColor);
      position: relative;
      padding: 8px;

      &:first-child {
        width: 48px;
      }
    }

    th {
      border-bottom: 0.5px solid var(--borderColor);
      position: relative;
      padding: 8px;
      font-weight: 500;
      font-size: 14px;
      color: $color_1;
      padding: 8px;
      text-transform: capitalize;
      border-bottom: 1px solid var(--borderColor);
    }

    .taskTime {
      padding-left: 16px;
      width: 45px !important;
    }

    &:last-child {
      td {
        border: none;
      }
    }
  }

  tbody {
    tr {
      &:hover {
        background: var(--backgroundColorOnHover);
      }

      td {
        .blueLabel {
          margin-right: 6px;
        }

        >.userLogoCont {
          width: 100%;
          justify-content: center;
          align-items: center;
          margin: 0px;
        }
      }
    }
  }

}

.tableFooter {
  border-top: 0.5px solid var(--borderColor);
}

.innerTableCont {
  padding-bottom: 0 !important;
}

.emailStat {
  background: var(--darkcolor);
  color: $color_2;
  height: 40px;
  width: 40px;
  padding: 10px;
  font-size: 1.2em;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.emailTimeStamp {
  border: 1px solid var(--darkcolor);
  background: #fff;
  min-width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 0.8em;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 4px;
  left: 15px;
}

.prospectName {
  display: flex;
}

.prospectDesg {
  font-size: 12px;
  font-style: italic;
  font-weight: normal;
}

.taskDesc {
  font-size: 13px;
}

.teamAverage {
  text-align: right;
  font-size: 16px;
  font-weight: normal;
}

.iconset {
  padding: 0;
  margin: 0;

  li {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-left: 6px;
    background: rgba(0, 0, 0, 0.05);
    color: $color_3;
    height: 36px;
    width: 36px;
    font-size: 0.7em;
    border-radius: 50%;
    text-align: center;
    cursor: pointer;

    &:nth-child(1) {
      margin-left: 0px;
    }
  }

  li.activeIcon {
    background: transparent;
    border: 1px solid var(--darkcolor);
    color: $color_3;
    width: 28px;
    height: 28px;
  }
}

.iconsetCont {
  text-align: right;
}

.smallIconTable {
  text-align: center;
}

.shadowTD {
  -webkit-box-shadow: 20px 0px 24px -16px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 20px 0px 24px -16px rgba(0, 0, 0, 0.25);
  box-shadow: 20px 0px 24px -16px rgba(0, 0, 0, 0.25);
}

.iconSetCont {
  li {
    img {
      width: 36px;
      height: 36px;
    }
  }
}

.iconSet {
  li {
    margin-left: 6px;
    cursor: pointer;
  }
}

.tooltipTd {
  padding: 0px !important;
  border: none !important;
}