.over {
  // transform: scale(1.01);
  opacity: 1;
  height: auto;
}

.draggable-container {
  .grab {
    >div:first-child {
      cursor: move;
      cursor: grab;
      cursor: -moz-grab;
      cursor: -webkit-grab;

      .thumbnails-list {
        cursor: pointer;
      }

      // border: 1px solid white;
      #sixDots {
        visibility: hidden;
      }

      &:hover {
        #sixDots {
          visibility: visible;
        }
      }

      &:active {
        cursor: grabbing;
        cursor: -moz-grabbing;
        cursor: -webkit-grabbing;
      }
    }

    .show-filters {
      transform: scale(1, 1);
      transition: width .5s ease-in-out;

      &:hover {
        transform: scale(1.03, 1);
      }
    }
  }


  .draggableList {
    .disposition-title {
      margin-left: 0px !important;
    }
  }
}